* {
  padding: 0;
  margin: 0;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}
@font-face {
  font-family: "Giaza";
  src: local("Giaza"), url(./assets/fonts/Giaza.ttf);
}

@font-face {
  font-family: "Glacial";
  src: local("Glacial"), url(./assets/fonts/Glacial.otf);
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@layer base {
  @font-face {
    font-family: Giaza;
    src: local("Giaza"), url(./assets/fonts/Giaza.ttf);
  }
  @font-face {
    font-family: "Glacial";
    src: local("Glacial"), url(./assets/fonts/Glacial.otf);
  }
  li {
    @apply px-4;
    @apply cursor-pointer;
  }
}

.content-div {
  height: 250px;
  max-width: 400px;
}
